<template>
    <div>
        <Modal v-model="showModal" title="Add Time Record" width="65%" no-footer :before-close="handleReset">
            <Form ref="form" :model="form" :rules="ruleInline" label-position="left" inline>
                <Row>
                    <Col span="8">
                        <FormItem label="Project" prop="selectedProject">
                            <Select
                                v-model="form.selectedProject"
                                filterable
                                clearable
                                @on-change="handleOnProjectChanged"
                            >
                                <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="Start Time" prop="startTime">
                            <DatePicker
                                v-model="form.startTime"
                                type="datetime"
                                placeholder="Select date"
                                format="dd-MMM-yyyy hh:mm:ss A"
                                transfer
                            />
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="End Time" prop="endTime">
                            <DatePicker
                                v-model="form.endTime"
                                type="datetime"
                                placeholder="Select date"
                                format="dd-MMM-yyyy hh:mm:ss A"
                                transfer
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
                <Button class="pt-btn-3" :loading="isLoading" @click="handleAddTimelog"> SAVE </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { postTimelogs, getAggregatedTimelogs } from '@/services/timelogs.services';
import { getProjectByProjectMembers } from '@/services/project-members.services';
export default {
    data() {
        return {
            isLoading: false,
            projectsList: [],
            timeLogsList: [],
            user_id: localStorage.getItem('user_id'),
            form: {
                startTime: null,
                endTime: null,
            },
            ruleInline: {
                selectedProject: [
                    {
                        required: true,
                        message: 'Please select Project',
                        trigger: 'change',
                    },
                ],
                startTime: [
                    {
                        required: true,
                        type: 'date',
                        message: 'Please fill in start time',
                        trigger: 'change',
                    },
                ],
                endTime: [
                    {
                        required: true,
                        type: 'date',
                        message: 'Please fill in end time',
                        trigger: 'change',
                    },
                ],
            },
            showModal: false,
        };
    },
    mounted() {
        // this.handleGetTimelogs()
        this.handleGetProjectsList();
    },
    methods: {
        handleGetTimelogs() {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs({})
                .then((res) => {
                    if (res.data.data.length) {
                        this.isLoading = true;
                        this.timeLogsList = res.data.data;
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get timelogs');
                    this.isLoading = false;
                });
        },

        handleAddTimelog() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.addTimelog();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },

        addTimelog() {
            const data = {
                ...this.form,
                project: this.form.selectedProject,
                user: this.user_id,
            };
            this.isLoading = true;
            postTimelogs(data)
                .then(() => {
                    this.$Message.success('Timelog successfully added');
                    this.isLoading = false;
                    this.$emit('add');
                    this.showModal = false;
                    this.$refs.form.resetFields();
                })
                .catch((err) => {
                    if (
                        err.data.statusCode === 400 &&
                        err.data.message === 'Start time and end time values should not be a future time'
                    ) {
                        this.$Message.error('Start time and end time values should not be a future time');
                    } else if (
                        err.data.statusCode === 400 &&
                        err.data.message === 'Overlapping timelogs with the current running,open timelog'
                    ) {
                        this.$Message.error('Overlapping timelogs with the current running,open timelog');
                    } else if (
                        (err.data.statusCode === 400 &&
                            err.data.message != 'Start time and end time values should not be a future time') ||
                        err.data.message != 'Overlapping timelogs with the current running,open timelog'
                    ) {
                        this.$Message.error('Failed to add timelog due to overlapping of timelog');
                    } else {
                        this.$Message.error('Failed to add timelog');
                    }
                    this.isLoading = false;
                });
        },

        handleGetTimelogsByProjectId(projId) {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs({ project: projId })
                .then((res) => {
                    if (res.data.data.length) {
                        this.isLoading = true;
                        this.timeLogsList = res.data.data.filter((i) => i.elem.project._id == projId);
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get project by id');
                    this.timeLogsList = [];
                    this.isLoading = false;
                });
        },

        handleGetProjectsList() {
            this.isLoading = true;
            this.projectsList = [];
            getProjectByProjectMembers(this.user_id)
                .then((res) => {
                    if (res.data.length) {
                        this.projectsList = res.data
                            .filter(
                                (x) =>
                                    x.project.isActive === true &&
                                    x.project.isArchived === false &&
                                    x.isProjectMemberActive === true
                            )
                            .map((i) => {
                                return {
                                    label: i.project.name,
                                    value: i.project._id,
                                };
                            });
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get projects');
                    this.isLoading = false;
                });
        },

        handleOnProjectChanged(projId) {
            this.selectedProject = projId;
        },

        handleGetTimeLogsByUser() {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs({ user: this.user_id })
                .then((res) => {
                    if (res.data.data.length) {
                        this.timeLogsList = res.data.data.filter((i) => i.elem.user._id == this.user_id);
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get timelogs by user');
                    this.timeLogsList = [];
                    this.isLoading = false;
                });
        },

        openModal() {
            this.showModal = true;
        },

        handleCancel() {
            this.showModal = false;
        },
        handleReset() {
            this.$refs.form.resetFields();
        },
    },
};
</script>
<style scoped>
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
}
.projClass {
    display: flex;
}
</style>
