<template>
    <Modal v-model="showModal" title="Quest Submission" footer-hide :before-close="handleReset">
        <div class="text-center">
            <Form ref="IncompleteForm" :model="formData" :rules="formRules">
                <FormItem prop="reason" class="incompleteReason">
                    <Input
                        v-model="formData.reason"
                        type="textarea"
                        :rows="4"
                        placeholder="Reason for incomplete quest"
                    />
                </FormItem>
                <FormItem class="no-margin incompleteReason">
                    <Button class="pt-btn-3" style="width: 150px" @click="handleSubmit"> OK </Button>
                </FormItem>
            </Form>
        </div>
    </Modal>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            formData: {
                reason: '',
            },
            formRules: {
                reason: [
                    {
                        required: true,
                        message: 'The reason cannot be empty',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },

    methods: {
        openModal() {
            this.showModal = true;
        },

        handleSubmit() {
            this.$refs.IncompleteForm.validate((valid) => {
                if (valid) {
                    this.$Message.success('Success!');
                } else {
                    this.$Message.error('Fail!');
                }
            });
        },

        handleReset() {
            this.$refs.IncompleteForm.resetFields();
        },
    },
};
</script>

<style lang="scss">
.incompleteReason .ivu-form-item-content {
    margin-left: unset;
    display: block;
}
</style>
