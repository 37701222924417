<template>
    <div>
        <Row :gutter="50" class="quest-types-wrapper xxl-margin-bottom xxl-margin-top">
            <Col :xs="24" :md="8">
                <Card
                    dis-hover
                    class="quest-type"
                    :class="selectedQuest === 'daily' ? 'selected-type' : ''"
                    @click.native="handleOnSelectQuest('daily')"
                >
                    DAILY QUEST
                </Card>
            </Col>
            <Col :xs="24" :md="8">
                <Card
                    dis-hover
                    class="quest-type"
                    :class="selectedQuest === 'weekly' ? 'selected-type' : ''"
                    @click.native="handleOnSelectQuest('weekly')"
                >
                    WEEKLY QUEST
                </Card>
            </Col>
            <Col :xs="24" :md="8">
                <Card
                    dis-hover
                    class="quest-type"
                    :class="selectedQuest === 'monthly' ? 'selected-type' : ''"
                    @click.native="handleOnSelectQuest('monthly')"
                >
                    MONTHLY QUEST
                </Card>
            </Col>
        </Row>
        <DailyQuest
            v-if="selectedQuest === 'daily'"
            class="md-margin-bottom"
            @incomplete="OpenIncompleteModal"
            @claim="OpenClaimModal"
        />
        <WeeklyQuest
            v-if="selectedQuest === 'weekly'"
            class="md-margin-bottom"
            @incomplete="OpenIncompleteModal"
            @claim="OpenClaimModal"
        />
        <MonthlyQuest
            v-if="selectedQuest === 'monthly'"
            class="md-margin-bottom"
            @incomplete="OpenIncompleteModal"
            @claim="OpenClaimModal"
        />
        <IncompleteModal ref="IncompleteModal" />
        <BaseModalConfirmation
            ref="BaseModalConfirmation"
            message="Your Daily Quest has been submitted !"
            :show-cancel="false"
            title="Claim"
        />
    </div>
</template>

<script>
import DailyQuest from '@/components/Rewards/DailyQuest';
import WeeklyQuest from '@/components/Rewards/WeeklyQuest';
import MonthlyQuest from '@/components/Rewards/MonthlyQuest';
import IncompleteModal from '@/components/Rewards/IncompleteModal';
import BaseModalConfirmation from '@/components/UI/BaseModalConfirmation';

export default {
    components: {
        DailyQuest,
        WeeklyQuest,
        MonthlyQuest,
        IncompleteModal,
        BaseModalConfirmation,
    },

    data() {
        return {
            selectedQuest: 'daily',
            isClaimable: false,
            dailyQuestStatus: {
                salariumChecked: false,
            },
        };
    },

    methods: {
        handleOnSelectQuest(type) {
            this.selectedQuest = type;
        },
        OpenIncompleteModal() {
            this.$refs.IncompleteModal.openModal();
        },

        OpenClaimModal() {
            this.$refs.BaseModalConfirmation.openModal();
        },
    },
};
</script>

<style lang="scss">
.quest-types-wrapper {
    text-align: center;
    .quest-type {
        width: 200px;
        margin: auto;
        border: 1px solid $error-color;
        color: $error-color;
        cursor: pointer;

        &:hover,
        &.selected-type {
            background: $error-color;
            color: #ffffff;
        }
    }
}
</style>
