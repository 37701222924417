<template>
    <div class="daily-quest-reward">
        <Card class="section-title">
            <Row>
                <Col flex="auto"> QUESTS </Col>
                <Col flex="195px" class="d-flex-center text-right">
                    <Button size="small" class="xs-margin-right" :disabled="isClaimable" @click="$emit('incomplete')">
                        INCOMPLETE
                    </Button>
                    <Button size="small" :disabled="isClaimable ? false : true" @click="$emit('claim')"> CLAIM </Button>
                </Col>
            </Row>
        </Card>
        <Row :gutter="50" class="md-margin-bottom">
            <Col :xs="24" :md="12">
                <InfoCard
                    message="Monday email Sent"
                    :status="weeklyQuestStatus.isMonEmailChecked"
                    @click.prevent="weeklyQuestStatus.isMonEmailChecked = !weeklyQuestStatus.isMonEmailChecked"
                />
            </Col>
            <Col :xs="24" :md="12">
                <InfoCard
                    message="Friday email Sent"
                    :status="weeklyQuestStatus.isFriEmailChecked"
                    @click.prevent="weeklyQuestStatus.isFriEmailChecked = !weeklyQuestStatus.isFriEmailChecked"
                />
            </Col>
        </Row>
        <!-- <Row :gutter="50" class="md-margin-bottom">
      <Col :xs="24" :md="12">
        <InfoCard message="Comment or Share LinkedIn Post(s)" :status="weeklyQuestStatus.isLinkedInChecked" @click.prevent="weeklyQuestStatus.isLinkedInChecked = !weeklyQuestStatus.isLinkedInChecked"/>
      </Col>
      <Col :xs="24" :md="12">
        <InfoCard message="Comment or Share Twitter Post(s)" :status="weeklyQuestStatus.isTwitterChecked" @click.prevent="weeklyQuestStatus.isTwitterChecked = !weeklyQuestStatus.isTwitterChecked"/>
      </Col>
    </Row>
    <Row :gutter="50" class="md-margin-bottom">
      <Col :xs="24" :md="12">
        <InfoCard message="Comment or Share Facebook Post(s)" :status="weeklyQuestStatus.isFacebookChecked" @click.prevent="weeklyQuestStatus.isFacebookChecked = !weeklyQuestStatus.isFacebookChecked"/>
      </Col>
    </Row> -->
    </div>
</template>

<script>
import InfoCard from '@/components/UI/InfoCard';
import { getUsersById } from '@/services/users.services.js';

export default {
    components: {
        InfoCard,
    },
    data() {
        return {
            isClaimable: false,
            weeklyQuestStatus: {
                isMonEmailChecked: false,
                isFriEmailChecked: false,
                // isLinkedInChecked: false,
                // isTwitterChecked: false,
                // isFacebookChecked: true
            },
        };
    },
    watch: {
        weeklyQuestStatus: {
            handler(data) {
                if (Object.values(data).every((item) => item)) {
                    this.isClaimable = true;
                } else {
                    this.isClaimable = false;
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.handleGetUserDetails();
    },
    methods: {
        handleGetUserDetails() {
            const userId = localStorage.getItem('user_id');
            getUsersById(userId).then((res) => {
                this.weeklyQuestStatus.isMonEmailChecked = res.data.monitoring_monEmail;
                this.weeklyQuestStatus.isFriEmailChecked = res.data.monitoring_friEmail;
            });
        },
    },
};
</script>

<style></style>
