<template>
    <InfoCard
        class="daily-timetracker-rewards"
        message="Daily Time Tracking"
        :status="isDailyTimeTracked"
        @click.prevent="isDailyTimeTracked = !isDailyTimeTracked"
    >
        <div slot="content" class="md-margin-top">
            <Table :columns="tblColumns" :data="timeLogsList" size="small" height="250" :loading="isLoading" />
        </div>
        <div slot="content" class="md-margin-top">
            <Button class="forgot" @click="timelogPopup"> Oups forgot! </Button>
        </div>
        <div slot="content" class="md-margin-top">
            <TimelogPopup ref="TimelogPopup" @add="handleAddList" />
        </div>
    </InfoCard>
</template>

<script>
import moment from 'moment';
import { getTimeTrackingByUser } from '@/services/timelogs.services';
import InfoCard from '@/components/UI/InfoCard';
import TimelogPopup from '@/components/Rewards/TimelogPopup';
export default {
    components: {
        InfoCard,
        TimelogPopup,
    },

    props: {
        isTracked: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            userId: localStorage.getItem('user_id'),
            mainRole: localStorage.getItem('mainRole'),
            timeLogsList: [],
            tblColumns: [
                {
                    title: 'Date',
                    key: 'createdAt',
                    width: 150,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.createdAt ? moment.utc(params.row.createdAt).format('DD-MMM-YYYY') : ''
                        );
                    },
                },
                {
                    title: 'Project',
                    key: 'project',
                    minWidth: 200,
                    render: (h, params) => {
                        return h('span', params.row.project && params.row.project.name ? params.row.project.name : '');
                    },
                },
                {
                    title: 'Start Time',
                    key: 'startTime',
                    width: 200,
                    sortable: true,
                    sortType: 'desc',
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.startTime
                                ? moment.utc(params.row.startTime).format('DD-MMM-YYYY hh:mm:ss A')
                                : ''
                        );
                    },
                },
                {
                    title: 'End Time',
                    key: 'endTime',
                    width: 200,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.endTime ? moment.utc(params.row.endTime).format('DD-MMM-YYYY hh:mm:ss A') : ''
                        );
                    },
                },
                {
                    title: 'Daily 8',
                    key: 'daily8',
                    width: 100,
                    render: (h, params) => {
                        return h('div', params.row.daily8);
                    },
                },
                {
                    title: 'Row Daily',
                    key: 'rowDaily',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.rowDaily);
                    },
                },
                {
                    title: 'Row Extra',
                    key: 'rowExtra',
                    width: 150,
                    render: (h, params) => {
                        // return h('div', (params.row.rowExtra))
                        if (params.row.rowExtra < 3) {
                            return h('div', { class: 'hyphenFont' }, '-');
                        } else {
                            return h('div', params.row.rowExtra);
                        }
                    },
                },
            ],
            tblData: [],
        };
    },

    computed: {
        isDailyTimeTracked: {
            get() {
                return this.isTracked;
            },
            set(val) {
                this.$emit('tracked', val);
            },
        },
    },

    mounted() {
        this.handleGetTimetrackingByUser();
    },

    methods: {
        handleGetTimetrackingByUser() {
            this.isLoading = true;
            this.timeLogsList = [];
            getTimeTrackingByUser(this.userId)
                .then((res) => {
                    if (res.data.length) {
                        if (moment().isBefore(moment('08:00:00', 'HH:mm:ss'), 'hours')) {
                            this.timeLogsList = res.data.filter((x) => moment(x.startTime).isSame(moment(), 'day'));
                        } else {
                            this.timeLogsList = res.data.filter((x) =>
                                moment(x.startTime).utc().isSame(moment().utc(), 'day')
                            );
                        }
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get timelogs by user');
                    this.timeLogsList = [];
                    this.isLoading = false;
                });
        },

        timelogPopup() {
            this.$refs.TimelogPopup.openModal();
        },

        handleAddList() {
            this.handleGetTimetrackingByUser();
        },
    },
};
</script>

<style>
button.forgot {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e9495d;
    border-radius: 10px;
    opacity: 1;
    margin: auto;
    display: block;
}
</style>
