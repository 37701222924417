<template>
    <div>
        <Card class="section-title">
            <Row>
                <Col flex="auto"> QUESTS </Col>
                <Col flex="195px" class="d-flex-center text-right">
                    <Button size="small" class="xs-margin-right" :disabled="isLearned" @click="$emit('incomplete')">
                        INCOMPLETE
                    </Button>
                    <Button size="small" :disabled="isClaimable ? false : true" @click="$emit('claim')"> CLAIM </Button>
                </Col>
            </Row>
        </Card>
        <Row :gutter="50" class="md-margin-bottom">
            <Col :xs="24" :md="12">
                <InfoCard
                    class="daily-timetracker-rewards"
                    message="What did I learn?"
                    :status="monthlyQuestStatus.isLearned"
                    @click.prevent="monthlyQuestStatus.isLearned = !monthlyQuestStatus.isLearned"
                >
                    <div slot="content" class="md-margin-top">
                        <Input
                            v-model="learnings"
                            type="textarea"
                            placeholder="Reason for incomplete quest"
                            :rows="4"
                        />
                    </div>
                </InfoCard>
            </Col>
            <Col :xs="24" :md="12">
                <InfoCard
                    message="Monthly report submitted"
                    :status="monthlyQuestStatus.reportSubmittedChecked"
                    @click.prevent="
                        monthlyQuestStatus.reportSubmittedChecked = !monthlyQuestStatus.reportSubmittedChecked
                    "
                />
            </Col>
        </Row>
    </div>
</template>

<script>
import InfoCard from '@/components/UI/InfoCard';

export default {
    components: {
        InfoCard,
    },

    data() {
        return {
            isClaimable: false,
            monthlyQuestStatus: {
                reportSubmittedChecked: false,
                isLearned: false,
            },
            learnings: null,
        };
    },

    watch: {
        monthlyQuestStatus: {
            handler(data) {
                if (Object.values(data).every((item) => item)) {
                    this.isClaimable = true;
                } else {
                    this.isClaimable = false;
                }
            },
            deep: true,
        },
    },
};
</script>

<style></style>
