<template>
    <div class="daily-quest-reward">
        <Card class="section-title">
            <Row>
                <Col flex="auto"> QUESTS </Col>
                <Col flex="195px" class="d-flex-center text-right">
                    <Button size="small" class="xs-margin-right" :disabled="isClaimable" @click="$emit('incomplete')">
                        INCOMPLETE
                    </Button>
                    <Button size="small" :disabled="isClaimable ? false : true" @click="$emit('claim')"> CLAIM </Button>
                </Col>
            </Row>
        </Card>
        <Row :gutter="50" class="md-margin-bottom">
            <Col :xs="24" :md="12">
                <InfoCard
                    message="Daily Checkin - Salarium"
                    :status="dailyQuestStatus.isSalariumChecked"
                    @click.prevent="dailyQuestStatus.isSalariumChecked = !dailyQuestStatus.isSalariumChecked"
                />
            </Col>
        </Row>
        <Row :gutter="50" class="md-margin-bottom">
            <Col :xs="24" :md="12">
                <InfoCard
                    message="Daily Standup on Slack"
                    :status="dailyQuestStatus.isSlackChecked"
                    @click.prevent="dailyQuestStatus.isSlackChecked = !dailyQuestStatus.isSlackChecked"
                />
            </Col>
            <Col :xs="24" :md="12">
                <InfoCard
                    message="Daily Joke / Cheer up / Quote / Personal Anecdote on Slack"
                    :status="dailyQuestStatus.isGoodHumored"
                    @click.prevent="dailyQuestStatus.isGoodHumored = !dailyQuestStatus.isGoodHumored"
                />
            </Col>
        </Row>
        <DailyTimeTracking
            class="md-margin-bottom"
            :is-tracked="dailyQuestStatus.isDailyTimeTracked"
            @tracked="(data) => (dailyQuestStatus.isDailyTimeTracked = data)"
        />
        <Row :gutter="50">
            <Col :xs="24" :md="12">
                <InfoCard
                    message="Daily Activity on Git"
                    :status="dailyQuestStatus.isGitChecked"
                    @click.prevent="dailyQuestStatus.isGitChecked = !dailyQuestStatus.isGitChecked"
                />
            </Col>
            <Col :xs="24" :md="12">
                <InfoCard
                    message="Daily Activity on Asana"
                    :status="dailyQuestStatus.isAsanaChecked"
                    @click.prevent="dailyQuestStatus.isAsanaChecked = !dailyQuestStatus.isAsanaChecked"
                />
            </Col>
        </Row>
    </div>
</template>

<script>
import moment from 'moment';
import InfoCard from '@/components/UI/InfoCard';
import DailyTimeTracking from '@/components/Rewards/DailyTimeTracking';
import { getUsersById } from '@/services/users.services.js';

export default {
    components: {
        InfoCard,
        DailyTimeTracking,
    },
    data() {
        return {
            isClaimable: false,
            dailyQuestStatus: {
                isSalariumChecked: false,
                isSlackChecked: false,
                isGoodHumored: false,
                isGitChecked: false,
                isAsanaChecked: false,
                isDailyTimeTracked: false,
            },
        };
    },
    watch: {
        dailyQuestStatus: {
            handler(data) {
                if (Object.values(data).every((item) => item)) {
                    this.isClaimable = true;
                } else {
                    this.isClaimable = false;
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.handleGetUserDetails();
    },
    methods: {
        handleGetUserDetails() {
            const userId = localStorage.getItem('user_id');
            getUsersById(userId)
                .then((res) => {
                    if (res.data.monitoring_last_time_tracker) {
                        const newDate = moment.utc(res.data.monitoring_last_time_tracker).format('YYYY-MM-DD');
                        const isAlreadyEightAM = moment().isBefore(moment('08:00:00', 'HH:mm:ss'), 'hours')
                            ? moment()
                            : moment().utc();
                        if (
                            newDate ===
                                isAlreadyEightAM
                                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                    .format('YYYY-MM-DD') &&
                            res.data.monitoring_last_time_tracker.includes(res.data.monitoring_last_time_tracker)
                        ) {
                            this.dailyQuestStatus.isDailyTimeTracked = true;
                        } else {
                            this.dailyQuestStatus.isDailyTimeTracked = false;
                        }
                    }
                    this.dailyQuestStatus.isSalariumChecked = res.data.monitoring_salarium;
                    this.dailyQuestStatus.isSlackChecked = res.data.monitoring_slack;
                    // this.dailyQuestStatus.isGoodHumored = res.data.monitoring_friEmail
                    this.dailyQuestStatus.isGitChecked = res.data.monitoring_git;
                    this.dailyQuestStatus.isAsanaChecked = res.data.monitoring_pmTool;
                })
                .catch(() => {
                    this.$Message.error('Failed to get users');
                });
        },
    },
};
</script>

<style></style>
